import React from "react";
import styles from "./footer.module.scss";
import Logo from "../../../assets/Icons/Logo.svg";

const Footer = () => {
  return (
    <>
      <div className={styles.Wrapperfooter}>
        <div className={styles.container}>
          <div>
            <img src={Logo} alt="no-icon" className={styles.logo} />
          </div>
          <div className={styles.content}>
            <p>Footer Text</p>
            <p>Footer Text</p>
            <p>Footer Text</p>
            <p>Footer Text</p>
          </div>
        </div>
        <div className={styles.lowerfooter}>
          <p>Copyright © 2022, NFTBond • Privacy Policy • Terms & Conditions</p>
        </div>
      </div>
    </>
  );
};
export default Footer;
