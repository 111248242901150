import { useState } from "react";
import Navigation from "../../component/_common/Navigation/Naviagtion";
import Footer from "../../component/_common/Footer/Footer";
const Layout = ({ children }) => {
  return (
    <div>
      <Navigation />
      <div>{children}</div>
      {/* <Footer /> */}
    </div>
  );
};
export default Layout;
