// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".homelayout_wrapper__6Jmj1 {\n  width: 100%;\n}\n.homelayout_wrapper__6Jmj1 .homelayout_container__84QW1 {\n  padding: 0rem 0.8rem;\n}\n\n@media screen and (min-width: 768px) {\n  .homelayout_wrapper__6Jmj1 {\n    width: 100%;\n  }\n  .homelayout_wrapper__6Jmj1 .homelayout_container__84QW1 {\n    padding: 0rem 3.3rem;\n  }\n}\n@media screen and (min-width: 1024px) {\n  .homelayout_wrapper__6Jmj1 {\n    width: 100%;\n  }\n  .homelayout_wrapper__6Jmj1 .homelayout_container__84QW1 {\n    padding: 0rem 5rem;\n  }\n}", "",{"version":3,"sources":["webpack://./src/component/_common/Layout/HomeLayout/homelayout.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,oBAAA;AAEJ;;AAEA;EACE;IACE,WAAA;EACF;EAAE;IACE,oBAAA;EAEJ;AACF;AAEA;EACE;IACE,WAAA;EAAF;EACE;IACE,kBAAA;EACJ;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  .container {\n    padding: 0rem 0.8rem;\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .wrapper {\n    width: 100%;\n    .container {\n      padding: 0rem 3.3rem;\n    }\n  }\n}\n\n@media screen and (min-width: 1024px) {\n  .wrapper {\n    width: 100%;\n    .container {\n      padding: 0rem 5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "homelayout_wrapper__6Jmj1",
	"container": "homelayout_container__84QW1"
};
export default ___CSS_LOADER_EXPORT___;
