import React from "react";
import styles from "./work.module.scss";
import Wallet from "../../../assets/Icons/wallet.svg";
import Purchase from "../../../assets/Icons/purchase.svg";
import Winner from "../../../assets/Icons/winner.svg";
import Collection from "../../../assets/Icons/collection.svg";
const HowItWork = () => {
  return (
    <>
      <div className={styles.workWrapper}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h1>How It works</h1>
          </div>
          <div className={styles.innerWrapper}>
            <div className={styles.cardWrapper}>
              <div className={styles.cardContainer}>
                <div className={styles.imageWrapper}>
                  <img src={Wallet} alt="no-icon" />
                </div>
                <div className={styles.contentWrapper}>
                  <h4>Set up your wallet</h4>
                  <p>
                    Once you’ve set up your wallet of choice, connect it to
                    OpenSea by clicking the wallet icon in the top right corner.
                    Learn about the wallets we support.
                  </p>
                </div>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.imageWrapper}>
                  <img src={Purchase} alt="n-icon" />
                </div>
                <div className={styles.contentWrapper}>
                  <h4>Purchase</h4>
                  <p>
                    Upload your work then Click My Collections and set up your
                    collection. Add social links, a description, profile &
                    banner images, and set a secondary sales fee.
                  </p>
                </div>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.imageWrapper}>
                  <img src={Winner} alt="no-icon" />
                </div>
                <div className={styles.contentWrapper}>
                  <h4>Winner</h4>
                  <p>
                    Choose between auctions, fixed-price listings, and
                    declining-price listings. You choose how you want to sell
                    your NFTs, and we help you sell them
                  </p>
                </div>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.imageWrapper}>
                  <img src={Collection} alt="no-icon" />
                </div>
                <div className={styles.contentWrapper}>
                  <h4>Collection</h4>
                  <p>
                    Once you’ve set up your wallet of choice, connect it to
                    OpenSea by clicking the wallet icon in the top right corner.
                    Learn about the wallets we support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HowItWork;
