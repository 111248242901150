import React from "react";
import BannerSection from "./Bannersection/BannerSection";
import Different from "./Different/Different";
import styles from "./home.module.scss";
const Home = () => {
  return (
    <>
      <div className={styles.wrapper}>
        {/* <BannerSection /> */}
        <div className={styles.container}>
          <Different />
        </div>
      </div>
    </>
  );
};
export default Home;
