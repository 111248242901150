import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomeLayoutPage from "../component/_common/Layout/HomeLayout/HomeLayout";
import About from "../component/About/About";
import { Router } from "react-router-dom";
import Layout from "../pages/Layout/Layout";
const PublicRoutes = () => {
  return (
    <>
      <BrowserRouter>
        {/* <Layout> */}
        <Routes>
          <Route path="/" element={<HomeLayoutPage />} />
          <Route path="/about" element={<About />} />
        </Routes>
        {/* </Layout> */}
      </BrowserRouter>
    </>
  );
};
export default PublicRoutes;
