import React from "react";
import styles from "./banner.module.scss";
import NFTBONDSCARD from "../../../assets/Icons/nftcardsbond.gif";
const BannerSection = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.innercontainer}>
            <div className={styles.left}>
              <div className={styles.content}>
                <h1>The Ultimate StableBonds NFTs.</h1>
                <p>
                  Buy, Win Prizes, Become a member of{" "}
                  <span style={{ color: "#CE6CF7" }}> {""}StableBond!</span>
                </p>
                <div className={styles.buttonwrapper}>
                  <button>Explore NFT’s Bond</button>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.gifwrapper}>
                <img src={NFTBONDSCARD} alt="no-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BannerSection;
