// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home_wrapper__XL0EH {\n  width: 100%;\n}\n.home_wrapper__XL0EH .home_container__if6Oc {\n  width: 100%;\n  padding: 0rem 0rem;\n}", "",{"version":3,"sources":["webpack://./src/component/Home/home.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,WAAA;EACA,kBAAA;AAEJ","sourcesContent":[".wrapper {\n  width: 100%;\n  .container {\n    width: 100%;\n    padding: 0rem 0rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "home_wrapper__XL0EH",
	"container": "home_container__if6Oc"
};
export default ___CSS_LOADER_EXPORT___;
