import "./App.css";
import Navigation from "./component/_common/Navigation/Naviagtion";
import Footer from "./component/_common/Footer/Footer";
import PublicRoutes from "./routes/PublicRoutes";
import Layout from "./pages/Layout/Layout";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import HomeLayoutPage from "./component/_common/Layout/HomeLayout/HomeLayout";
import About from "./component/About/About";
import PurchaseBond from "./component/PurchaseBonds/PurchaseBonds";
function App() {
  return (
    <>
      {/* <Navigation /> */}
      {/* <Routes /> */}
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<HomeLayoutPage />} />
            <Route path="/purchasebond" element={<PurchaseBond />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Layout>
      </BrowserRouter>

      {/* <Footer /> */}
    </>
  );
}

export default App;
