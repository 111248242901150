import React from "react";
import styles from "./different.module.scss";
import Coins from "../../../assets/Icons/coins.svg";
import Stable from "../../../assets/Icons/stable.svg";
import Liquidity from "../../../assets/Icons/liquidity.svg";
const Different = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.upperContent}>
            <div className={styles.upperleft}>
              <h4>About us</h4>
              <h1>Why we are different with others?</h1>
            </div>
            <div className={styles.uperright}>
              <p>
                NFT Bond is a generative bond NFT collection made up of 10,000
                unique, original, advantageous utilities offered to its holders.
              </p>
              <p className={styles.explore}>Explore Now</p>
            </div>
          </div>
          <div className={styles.lowercontent}>
            <div className={styles.cardWrapper}>
              <div className={styles.card}>
                <div className={styles.imagewrapper}>
                  <img src={Coins} />
                </div>
                <div className={styles.content}>
                  <h2>Freedom of choice</h2>
                  <p>
                    Once you’ve set up your wallet of choice, connect it to
                    OpenSea by clicking the wallet icon in the top right corner.
                    Learn about the wallets we support.
                  </p>
                </div>
              </div>

              <div className={styles.card}>
                <div className={styles.imagewrapper}>
                  <img src={Stable} />
                </div>
                <div className={styles.content}>
                  <h2>Fast & Stable System</h2>
                  <p>
                    Upload your work then Click My Collections and set up your
                    collection. Add social links, a description, profile &
                    banner images, and set a secondary sales fee.
                  </p>
                </div>
              </div>

              <div className={styles.card}>
                <div className={styles.imagewrapper}>
                  <img src={Liquidity} />
                </div>
                <div className={styles.content}>
                  <h2>Deep liquidity</h2>
                  <p>
                    Once you’ve set up your wallet of choice, connect it to
                    OpenSea by clicking the wallet icon in the top right corner.
                    Learn about the wallets we support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Different;
