import React, { useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";

import styles from "./navigation.module.scss";
import { slide as Menu } from "react-burger-menu";
import Logo from "../../../assets/Icons/Logo.svg";
import MenuIcon from "../../../assets/Icons/Menu.svg";
const Navigation = () => {
  const Navigate = useNavigate();
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [active, setActive] = useState(0);
  const stateChangeHandler = (newState) => {
    setMenuOpenState(newState.isOpen);
  };
  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };
  const handleNaviagte = (data) => {
    Navigate(data);
  };
  return (
    <>
      <Menu
        right
        disableAutoFocus
        customBurgerIcon={false}
        isOpen={menuOpenState}
        onStateChange={(state) => stateChangeHandler(state)}
        itemListElement="div"
        noOverlay
      >
        <div className={styles.menuWrapper}>
          <div className={styles.logo}>
            <img src={Logo} alt="logo" className={styles.img} />
          </div>
          <div className={styles.links}>
            <nav>
              <ul>
                <li onClick={() => handleNaviagte("/about")}>Abouts us</li>
                <Link to="/purchasebond">
                  <li>Purchase bonds</li>
                </Link>

                <li>Contact us</li>
                <li>FAQs</li>
                <li>
                  <div className={styles.buttonwrapper}>
                    <div className={styles.btn}>
                      <span className={styles.spanbtn}>Connect Wallet</span>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Menu>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <img
              src={Logo}
              alt="logo"
              className={styles.img}
              height={200}
              width={200}
            />
          </div>
          <div className={styles.menubar}>
            <img
              src={MenuIcon}
              alt="logo"
              onClick={handleMenu}
              height={25}
              width={25}
            />
          </div>
          <div className={styles.menuItems}>
            <div className={styles.rightmenu}>
              <div className={styles.inactive}>
                <div
                  className={styles.item}
                  // className={asPath == "/trades" ? styles.active : styles.item}
                >
                  {/* <Link href="/trades" className={styles.text}> */}
                  {/* <Link to="/about"> */}
                  {/* <p>
                    <a>About us</a>
                  </p> */}
                  {/* </Link> */}
                  <Link to="/about" className={styles.item}>
                    <a>About Us</a>
                  </Link>

                  {/* </Link> */}
                </div>
              </div>

              <div className={styles.inactive}>
                <div
                  className={styles.item}
                  // className={asPath == "/trades" ? styles.active : styles.item}
                >
                  <Link to="/purchasebond">
                    <a>Purchase bonds</a>
                  </Link>
                </div>
              </div>
              <div className={styles.inactive}>
                <div
                  className={styles.item}
                  // className={asPath == "/trades" ? styles.active : styles.item}
                >
                  {/* <Link href="/trades" className={styles.text}> */}
                  <p>
                    <a>Contact Us</a>
                  </p>
                  {/* </Link> */}
                </div>
              </div>
              <div className={styles.inactive}>
                <div
                  className={styles.item}
                  // className={asPath == "/trades" ? styles.active : styles.item}
                >
                  {/* <Link href="/trades" className={styles.text}> */}
                  <p>
                    <a>FAQs</a>
                  </p>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonwrapper}>
            <div className={styles.btn}>
              <span className={styles.spanbtn}>Connect Wallet</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
