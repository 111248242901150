import React from "react";

const PurchaseBond = () => {
  return (
    <>
      <div>PurchaseBond</div>
    </>
  );
};
export default PurchaseBond;
