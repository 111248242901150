import React from "react";
import styles from "./homelayout.module.scss";
import Home from "../../../Home/Home";
import BannerSection from "../../../Home/Bannersection/BannerSection";
import HowItWork from "../../../Home/HowItWork/HowWork";
const HomeLayoutPage = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <BannerSection />
        <div className={styles.container}>
          <Home />
        </div>
        <HowItWork />
      </div>
    </>
  );
};

export default HomeLayoutPage;
